html, body {
  height: 100%;
  background-color: #292C33;
}

*, *:after, *:before {
  box-sizing: border-box;
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
